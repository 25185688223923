@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
    padding: 0;
    margin: 0;
    font-family: 'Poppins','Montserrat', sans-serif; ; 
}

body{
    width: 100vw;
    height: 100vh;
    background-color: #02021A;
    display:flex;
    flex-direction: column;
}


/* body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.contHome{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #02021A; */
}
.contHeader{
    display: flex;
    
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media (max-width: 768px){
    .contHeader{
        flex-direction: column;
        margin: 1rem;
    }
}

.Logo{
    background-color: #ecf0f1;
    border-radius: 50%;
    padding: 0.5rem 0.5rem;
}
.navbarMenu{
    margin-left: 1rem;
    display: flex;
    background-color: rgba(165, 42, 42, 0);
    color: white;
    list-style: none;
}

.navbarMenu li {
    margin: 0px 0.2rem;
    background-color: #30336b;
    padding: 1rem 1rem;
    list-style: none;
    color: white;
    border-radius: 10px;
}

.navbarMenu li:hover{
    background-color: rgb(48, 17, 105);
    transition: all 0.5s ease;
    color: black;
}


.navbarMenu li a{
    text-decoration: none;
    color: white;
}

@media (max-width: 768px){
    .navbarMenu{
        margin: 1rem 0rem;
    }
    
    .navbarMenu li {
        padding: 1rem 0.4rem;
    }

}
.contPresentation{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0rem;
}
.title-welcome{
    text-align: center;
    Color: #fff;
    padding-bottom: 1rem;
}

@media (max-width: 768px){
    .title-welcome{
        font-size: 1.5rem;
        padding: 0rem 1.5rem 1rem 1.5rem;
    }
}
.text-features{
    text-align: center;
    Color: #fff;
    padding-bottom: 1rem;
    letter-spacing: 3px;
}
.text-detail{
    text-align: center;
    Color: #fff;
    padding-bottom: 1rem;
    margin: 0px 2rem;
}
.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #060630;
    width: 20rem;
    /* height: 20rem; */
    border-radius: 0.5rem;
    box-shadow: 0px 0px 20px#4834d4;
    padding: 1rem 0.5rem;
    margin: 1rem 0px;
}

.img-card{
    max-height: 100%;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 20px#4834d4;
    width: 90%;
    margin: 1rem 0.5rem;
}

.title{
    padding: 0.7rem 0rem;
    color: #F2F136;
}

@media (max-width: 768px){
    .card{
        width:70%;
    }
}
.cont-footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #282b5c;
    padding: 1rem 0rem;
}
.cont-social-media{
    display: flex;
    justify-content: center;
    width: 100%;
}

.social{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    margin: 0rem 1rem;
    font-size: 10px;
}

.social img{
    max-width: 50px ;
}

.social:hover{
    color: aqua;
    transition: all 0.5s ease
}
.text-credits{
    display: flex;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin-top: 5px;
}
