.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #060630;
    width: 20rem;
    /* height: 20rem; */
    border-radius: 0.5rem;
    box-shadow: 0px 0px 20px#4834d4;
    padding: 1rem 0.5rem;
    margin: 1rem 0px;
}

.img-card{
    max-height: 100%;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 20px#4834d4;
    width: 90%;
    margin: 1rem 0.5rem;
}

.title{
    padding: 0.7rem 0rem;
    color: #F2F136;
}

@media (max-width: 768px){
    .card{
        width:70%;
    }
}