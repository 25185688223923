.title-welcome{
    text-align: center;
    Color: #fff;
    padding-bottom: 1rem;
}

@media (max-width: 768px){
    .title-welcome{
        font-size: 1.5rem;
        padding: 0rem 1.5rem 1rem 1.5rem;
    }
}